/* .App {
  text-align: center;
} */

.App-logo {
  height: 20vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}
.header-link{
  color: #e57534;
  text-decoration: none;
}
.header-link:hover{
  color: #ffffff;
}
.header {
  z-index: 2;
  background-color: #000!important;
}
.header .MuiButton-text {
  color: #e57534;
}
.grow {
  flex-grow: 1;
}
