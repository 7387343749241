// Variable overrides
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap");


$theme-colors: (
  "success": #8b33cd,
  "primary": #f4ba18,
  "danger": #ff4136,
  "dark": #666666,
);
:root {
  --primary-dark-theme: #4638c2;
  --secondary-dark-theme: #4c4f54;
  --success-dark-theme: #45a164;
  --info-dark-theme: #4799eb;
  --warning-dark-theme: #e1a82d;
  --danger-dark-theme: #d16767;
  --light-dark-theme: #666666;
  --dark-dark-theme: #333333;
}
$main: #b758ff;
$main-darck: #6b0ae0;
$main-bg: #253741;
$dark: #666666;
$dark2: #333333;
$dark3: #707070;
$border: #aaaaaa;
$lightBG: #eee;
$greyBG: #ccc;
$lightGrey: #f4f4f4;
$black: #000;
$whiteBG: #fff;
$blue:#3272D7;
$beige: #fdf1cc;

$mediaMaxWidth: 1260px;
$mediaBp1Width: 960px;
$mediaMinWidth: 480px;

$minor:#00e600;
$intermediate:#0072d5;
$moderate:#fcb900;
$sever:#ff0000;
$critical:#c380ae;
$dirt:#B45F06;


$lightYellow:#fadc7f;
$lightGreen:#7FD87F
