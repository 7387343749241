@import 'variables';


.content{
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-around;
}
.container{
  max-width: 1080px;
 
  padding:0px 50px;

}

.main-content{
  display: flex;
  


}

.left-side{
  width: 50% !important;
  line-height: 41px;

  .left-side-content{
    justify-content: center!important;
    padding: 0 30px;
/* From https://css.glass */
background: rgba(175, 66, 12, 0.08);
border-radius: 16px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(17.2px);
-webkit-backdrop-filter: blur(17.2px);
border: 1px solid rgba(250, 85, 3, 0.3);

  }

  .form-control{
    width: 100%;
    background: #f8fafb;;
  }

  .col-md-8{
    color: black;

    h3{
      color: black;
    }
  }
}

.right-side{
  width: 50%;
  display: flex;

  img{
    position: relative;
    left: 20rem;
    bottom: 10rem;
    width: 100%;
    
  }
}

p {
  color: #b3b3b3;
  font-weight: 300; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: "Roboto", sans-serif; }

a {
  -webkit-transition: .3s all ease;
  -o-transition: .3s all ease;
  transition: .3s all ease; }
  a:hover {
    text-decoration: none !important; }

.content {
  // padding-top: 5rem;
  min-height: calc(100vh - 223px - 5rem);
}

h2 {
  font-size: 20px; }
  


@media (max-width: 991.98px) {
  .content .bg {
    height: 500px; } }

.content .contents, .content .bg {
  width: 50%; }
  @media (max-width: 1199.98px) {
    .content .contents, .content .bg {
      width: 100%; } }
  .content .contents .form-group, .content .bg .form-group {
    position: relative; }
    .content .contents .form-group label, .content .bg .form-group label {
      position: absolute;
      top: 50%;
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      -webkit-transition: .3s all ease;
      -o-transition: .3s all ease;
      transition: .3s all ease; }
    .content .contents .form-group input, .content .bg .form-group input {
      background: transparent;
      border-bottom: 1px solid #ccc; }
    .content .contents .form-group.first, .content .bg .form-group.first {
      border-top-left-radius: 7px;
      border-top-right-radius: 7px; 
      margin-bottom: 1rem;
    }
    .content .contents .form-group.last, .content .bg .form-group.last {
      border-bottom-left-radius: 7px;
      border-bottom-right-radius: 7px; }
    .content .contents .form-group label, .content .bg .form-group label {
      font-size: 12px;
      display: block;
      margin-bottom: 0;
      color: #b3b3b3; }
    .content .contents .form-group.focus, .content .bg .form-group.focus {
      background: #fff; }
    .content .contents .form-group.field--not-empty label, .content .bg .form-group.field--not-empty label {
      margin-top: -25px;
    width: 100%; }
  .content .contents .form-control, .content .bg .form-control {
    border: none;
    padding: 0;
    font-size: 20px;
    border-radius: 0; }
    .content .contents .form-control:active, .content .contents .form-control:focus, .content .bg .form-control:active, .content .bg .form-control:focus {
      outline: none;
      -webkit-box-shadow: none;
      box-shadow: none; }

.content .bg {
  background-size: cover;
  background-position: center; }

.content a {
  color: #000000;
  text-decoration: underline; }

.btn {
  height: 59px;
  padding-left: 30px;
  padding-right: 30px;
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  transition: .1s ease-in-out;
  text-decoration: none;
  list-style: none;
}

.btn:hover{
  transform: scale(1.050);
}

.content .forgot-pass {
  position: relative;
  top: 2px;
  font-size: 14px; 
  list-style: none;
  text-decoration: none;
}

.content .forgot-pass:hover{
 color:red;
}

.content .edit-configs{
  text-decoration: none;
  list-style: none;
}

.content .edit-configs:hover{
  color:green;
}


.social-login a {
  text-decoration: none;
  position: relative;
  text-align: center;
  color: #fff;
  margin-bottom: 10px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: inline-block; }
  .social-login a span {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); }
  .social-login a:hover {
    color: #fff; }
  .social-login a.facebook {
    background: #3b5998; }
    .social-login a.facebook:hover {
      background: #344e86; }
  .social-login a.twitter {
    background: #1da1f2; }
    .social-login a.twitter:hover {
      background: #0d95e8; }
  .social-login a.google {
    background: #ea4335; }
    .social-login a.google:hover {
      background: #e82e1e; }

.control {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 15px;
  cursor: pointer;
  font-size: 14px; }
  .control .caption {
    position: relative;
    top: .2rem;
    color: #888; }

.control input {
  position: absolute;
  z-index: -1;
  opacity: 0; }

.control__indicator {
  position: absolute;
  top: 14px;
  left: 0;
  height: 20px;
  width: 20px;
  background: #e6e6e6;
  border-radius: 4px; }

.control--radio .control__indicator {
  border-radius: 50%; }

.control:hover input ~ .control__indicator,
.control input:focus ~ .control__indicator {
  background: #ccc; }

.control input:checked ~ .control__indicator {
  background: $main-darck; }

.control:hover input:not([disabled]):checked ~ .control__indicator,
.control input:checked:focus ~ .control__indicator {
  background: $main-darck; }

.control input:disabled ~ .control__indicator {
  background: #e6e6e6;
  opacity: 0.9;
  pointer-events: none; }

.control__indicator:after {
  font-family: 'icomoon', sans-serif;
  content: '\e5ca';
  position: absolute;
  display: none;
  font-size: 16px;
  -webkit-transition: .3s all ease;
  -o-transition: .3s all ease;
  transition: .3s all ease; }

.control input:checked ~ .control__indicator:after {
  display: block;
  color: #fff; }

.control--checkbox .control__indicator:after {
  top: 50%;
  left: 50%;
  margin-top: -1px;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%); }

.control--checkbox input:disabled ~ .control__indicator:after {
  border-color: $main-darck; }

.control--checkbox input:disabled:checked ~ .control__indicator {
  background-color: $main;
  opacity: .2; }

.btn-green{
  background-color: #e57534;;
  border-color: #e57534;;
  width: 100%;
}
.text-white{
  color:#fff;
  font-size: 18px;
  font-weight: 600;
  text-decoration: none;
}
.btn-small{
  width: 320px;
  // margin: 0 auto;
}

.pasteButtonArm{
  position: relative;
  left: 12.5rem;
  width: 300px;
  bottom: 60px;
  height: 40px;
  display: flex;
  justify-content: center;
  background-color: $intermediate;
  color:white;
  border-radius: 6px;
  cursor: pointer;
  transition: .3s ease-in-out;
}

.pasteButtonArm:hover, .pasteButtonEng:hover{
  transform: scale(1.03);
}

.pasteButtonArm:hover, .pasteButtonEng:hover{
  background-color: #1b8cef;
}

.pasteButtonEng{
  position: relative;
  left: 12rem;
  width: 220px;
  bottom: 60px;
  height: 40px;
  display: flex;
  justify-content: center;
  background-color: $intermediate;
  color:white;
  border-radius: 6px;
  cursor: pointer;
  transition: .3s ease-in-out;
}


.pasteInfoText{
  position: relative;
  right: 12px;
}

.visibilityButton{
  // position: relative;
  left: 27rem;
  bottom: 4rem;
}

.marginBottom{
  margin-bottom: 5rem;
}

.checkBox{
  position: relative;
  
}

.log-out{
  text-decoration: none;
  font-weight: bold;
  display: flex;
  justify-content: center;
  margin: 10px;
}

.log-out-icon{
  margin-left: 5px;
}

.log-out:hover{
  color: red;
}

.log-in-icon{
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  list-style: none;
}

.language-style{
  display:flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 40px;
}
.passPhraseConf{
  position: relative;
  top: 3px;
}

.fireBaseInput{
  width: 478px;
}

.empty-space{
  margin-bottom: 10px;
}

.content a{
  text-decoration: none;
}

.languageClass{
  position: relative;
  margin-right: 20px;
}

@media (max-width: 680px) {
  .left-side .left-side-content{
    padding: 0 0px;
  }
}