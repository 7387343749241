.page-break { page-break-after: always; }

html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  width: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
// ::-webkit-scrollbar-thumb {
//   background: #e57534; 
// }

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

body {
  font-family: 'Roboto', sans-serif;
  background-color: #f8fafb;
}
// If you want to override variables do it here
@import 'variables';

// If you want to add something do it here
@import 'custom';
////core ui icons
.row {
}
.loading {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000000;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 60px;
    height: 60px;
  }
}
//span[title]{
//  background-color: $dark2;
//}
[data-title]:hover:after {
  opacity: 1;
  transition: all 0.1s ease 0.5s;
  visibility: visible;
}
[data-title]:after {
  content: attr(data-title);
  background-color: $dark;
  color: #fff;
  position: absolute;
  padding: 1px 5px 1px 5px;
  bottom: -2em;
  left: 10%;
  font-size: 12px;
  font-weight: 500;
  white-space: nowrap;
  opacity: 0;
  box-shadow: 1px 1px 3px solid $dark2;
  border-radius: 3px;
  z-index: 99999;
  visibility: hidden;
}
[data-title] {
  position: relative;
}
.margin-center {
  display: block;
  margin: 0 auto;
}
.button {
  min-width: 125px;
  height: 56px;
  background-color: $border;
  margin: 5px 0 5px;
  color: #fff;
  font-weight: 600;
  font-size: 18px;
  border-radius: 10px;
  border: 0;
}
.button-text-icon {
  color: $dark;
  font-size: 18px;
  border: 0;
  margin: 0 20px;
  svg {
    fill: $dark;
    width: 24px;
    height: 24px;
    margin-left: 20px;
  }
  .leftArrow {
    transform: rotate(180deg);
  }
}
.button-text {
  color: $dark;
  font-size: 18px;
  font-weight: bold;
  border: 0;
  margin: 0 30px;
}
.custom-radio {
  min-width: 1rem;
  margin-right: 3px;
  input {
    position: absolute;
    z-index: -1;
    width: 1rem;
    height: 1.15625rem;
    opacity: 0;
  }
  input[type='radio']:checked + label::before {
    color: #fff;
    border-color: #f4ba18;
    background-color: #f4ba18;
  }
  label {
    position: relative;
    margin-bottom: 0;
    vertical-align: top;
    &::before {
      position: absolute;
      top: 0.15625rem;
      display: block;
      width: 1rem;
      height: 1rem;
      pointer-events: none;
      content: '';
      border: solid 1px #9da5b1;
      background-color: #fff;
      border-radius: 50%;
    }
    &::after {
      position: absolute;
      top: 0.15625rem;
      display: block;
      width: 1rem;
      height: 1rem;
      content: '';
      background: no-repeat 50% / 50% 50%;
    }
  }
}
.MuiDialogContent-root {
  .card {
    padding: 10px;
  }
}
.MuiDialog-paperWidthSm {
  //width: inherit!important;
  max-width: none !important;
}
.MuiDialogTitle-root {
  display: flex;
  justify-content: space-between;
  padding: 5px 24px 0 !important;
  border-radius: 10px;
  h6 {
    color: $dark;
    font-size: 4vh;
    font-weight: bold;
    padding: 15px 0 5px 10px;
  }
  .MuiSvgIcon-root {
    fill: $greyBG;
    width: 24px;
  }
}

.dialog {
  background-color: $lightBG;
  border-radius: 10px;
  .header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $dark;
    padding: 10px;
  }
  .search {
    justify-content: revert;
    flex-direction: row-reverse;
    border: 1px solid $greyBG;
    border-radius: 5px;
    padding: 0 0 0 10px;
    input {
      color: $dark;
    }
    svg {
      fill: $border;
    }

    button {
      color: $border;
    }
  }

  .buttons {
    display: flex;
    justify-content: flex-end;
  }
  .Selection-buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .button-text-app {
      width: 151px;
      height: 48px;
      border: 1px solid #aaaaaa;
      border-radius: 10px;
      margin: 0 15px;
      color: #666666;
      font-size: 18px;
      font-weight: bold;
      font-family: 'Roboto', sans-serif;
    }
    .button-cnt {
      width: 125px;
      height: 48px;
      border-radius: 10px;
      background: #aaaaaa;
      color: #fff;
      border: none;
      outline: none;
      font-size: 18px;
      font-weight: bold;
      font-family: 'Roboto', sans-serif;
    }
  }

  .card {
    border-radius: 10px;
  }
}
.c-main {
  padding: 0;
}
.btn-success {
  background-color: $main !important;
  color: #fff;
}
.btn-link {
  color: $main-bg;
  &:hover {
    color: $main;
  }
}

.bg-gradient-primary {
  background: $main !important;
  background: linear-gradient(45deg, $main 0%, $main-darck 100%) !important;
  border-color: $main !important;
}
.list {
  th {
    color: #000;
  }
}
.list tbody tr:nth-child(4n + 1) {
  background-color: rgba(0, 0, 21, 0.05);
  &:hover {
    background-color: rgba(0, 0, 21, 0.1);
  }
}
.list-title {
  margin-right: 15px;
  display: inline-block;
  min-width: 143px;
}
.logo {
  width: 100%;
}
.c-sidebar-brand {
  padding: 10px 0;
  flex-direction: column;
  text-decoration: none;
  h4 {
    color: $main;
    margin-top: 10px;
    text-decoration: none;
  }
}
.c-header {
  height: 110px;
  box-shadow: none !important;
  .dropdown {
    margin-left: 10px;
    font-weight: 600;
  }
  .c-header-nav {
    height: 60px;
  }
  .c-header-brand {
    height: 46px;
  }

  &.c-header-dark .c-subheader {
    border-top: 0;
    box-shadow: 0 10px 20px #00000020;
  }
  .icon {
    &.que {
      fill: $main;
      width: 30px;
      height: 30px;
    }
    &.share {
      fill: #bbb;
      width: 15px;
      height: 17px;
      &:hover {
        fill: #f5ba00;
      }
    }
  }
  h4 {
    margin-bottom: 0;
  }
}
.tool-bar {
  padding-left: 9px;
  .icon {
    width: 30px;
    height: 30px;
  }
  .Redo .icon {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  }
  .complete-icon-yell {
    fill: #f5ba00;
  }
  .complete-icon-gree {
    fill: #00b200;
  }
  .complete-persentage {
    font-size: 30px;
  }
  a,
  span {
    cursor: pointer;
    margin-left: 3px;
    padding: 4px !important;
    &.active {
      background: #fff;
      outline: 1px solid #ccc;
    }
    // &:hover {
    //   background: #eee;
    // }
  }
  .redo {
    transform: scaleX(-1);
  }
  .overide_ {
    svg {
      height: 38px;
    }
  }
  .fit_ {
    margin: 0 5px;
  }
}
.select-grid {
  padding: 20px;
  .radio {
    img {
      width: 40px;
    }
    label {
      min-width: 100px;
      margin-right: 7px;
    }
  }
}
.c-header-brand {
  //padding: 10px 0;
  //flex-direction: column;
  //text-decoration: none;
  min-height: 30px;
  height: 30px;
  img {
    width: 200px;
  }
  h1 {
    color: #fff;
    margin: 0;
    padding: 0;
    font-size: 36px;
    text-decoration: none;

    span {
      font-weight: 600;
    }

    i {
      font-weight: 300;
    }
  }
}

.multiline {
  position: relative;
  width: 100%;
  margin-top: 20px;

  .MuiFormControl-root {
    width: 100%;
  }

  .icon {
    position: absolute;
    top: 7px;
    right: 7px;
  }
}
//.MuiPaper-root {
//  background-color: $dark !important;
//}
.icon {
  width: 21px;
  height: 21px;
  color: $dark;
  fill: $dark;
}
.c-dark-theme .MuiPaper-root {
  background-color: $dark !important;
}
.create {
  width: 100%;

  .form-control {
    min-height: 35px;
  }
  .input-field {
    background-color: #fff;
    .MuiInputBase-root {
      width: 100%;
    }
    p {
      margin: 0;
    }
    input {
      //padding: 10px;
    }
  }

  form {
    min-width: 150px;
  }

  .person-type {
    label {
      display: none;
    }

    h5 {
      margin-right: 10px;
    }
    form {
      padding-bottom: 0;
    }
  }
  .MuiOutlinedInput-input {
    padding: 9px;
  }
  .MuiInputLabel-outlined {
    transform: translate(14px, 8px) scale(0.75);
  }
}
.env-drop {
  width: 100%;
}
.MuiFormLabel-root {
  color: #acacac;
}

.error {
  color: red !important;
  input {
    border: 1px solid red !important;
  }
}

.form-control-warning {
  .MuiInputBase-root {
    border: solid 1px red;
  }
}
.form-group {
  width: 100%;
}

.save-container{
  max-width: 350px;
  display: flex;
  padding-left: 800px;
}
.flex-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;
  margin-left: 10px;
}
.boolean {
  display: flex;
  align-items: center;
  label {
    margin-bottom: 0;
  }
}
.call-4 {
  width: 32%;
  margin-right: 2%;
  display: inline-block;
  &.MuiFormControl-root {
    margin-right: 2%;
    &:nth-child(3n) {
      margin-right: 0;
    }
  }
  &:nth-child(3n) {
    margin-right: 0;
  }
}
.call-3 {
  width: 25%;
  display: inline-block;
}
.call-6 {
  width: 48%;
  margin-right: 4%;
  display: inline-block;
  &:nth-child(2n + 1) {
    margin-right: 0;
  }
}
.call-4-alone {
  width: 32%;
  margin-right: 68% !important;
}
.circle_header {
  width: 3px;
  height: 3px;
  display: inline-block;
  background: #000;
  border-radius: 15px;
  margin: 0 0 5px 7px;
}
.geo-icon {
  padding: 0;
}
.img {
  padding: 10px;
  width: 100px;
  img {
    width: 100%;
  }
}
.info {
  //padding: 10px;
}
.table{
  border: 1px solid #ccc;
  table-layout: fixed;
}

.table th,
.table td {
  padding: 0.2rem;
}
.table thead th {
  font-size: 15px;
  border: 0;
  background-color: #e7ebfb;
}

.actions {
  display: flex;
  padding: 0 15px;
  flex-direction: column;
  svg {
    margin: 5px 3px;
    width: 15px;
    height: 15px;
    fill: $border;
  }
}
.address {
  display: flex;
  justify-content: space-between;
  .actions {
    flex-direction: row;
  }
}

.persons {
  display: flex;
  .person {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 3px solid $dark;
    color: $dark;
    background-color: $dark;
    font-size: 24px;
    font-weight: 400;
    margin-left: -10px;
    overflow: hidden;
    span {
      width: 100%;
      height: 100%;
      background-color: #f5ba00;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 0 3px 20px #00000016;
    }
    &:first-child {
      margin-left: 0;
    }
  }
  .p1 {
    z-index: 3;
    color: #fff;
    &:hover .user_info-dropdown {
      visibility: visible;
    }
  }
  .p2 {
    z-index: 2;
    span {
      opacity: 0.8;
    }
    &:hover .user_info-dropdown {
      visibility: visible;
    }
  }
  .p3 {
    z-index: 1;
    span {
      opacity: 0.6;
    }
    &:hover .user_info-dropdown {
      visibility: visible;
    }
  }
}
.user_info-dropdown {
  height: 390px;
  width: 238px;
  position: absolute;
  z-index: 9;
  background: #666666;
  color: #eee;
  top: 51px;
  visibility: hidden;
  right: 0;
  p.nik-name {
    padding: 15px 20px;
    font-weight: bold;
    font-family: 'Roboto', sans-serif;
  }
  svg.star {
    width: 100px !important;
  }
  .star-icon {
    padding: 10px 20px;
  }
  .pref_ {
    padding: 0 20px;
    display: flex;
    align-items: center;
    p {
      font-weight: bold;
      font-family: 'Roboto', sans-serif;
      font-size: 18px;
      margin: 0 15px;
    }
  }
  .account_circle_user {
    width: 150px;
    height: 150px;
    background: #000;
    padding: 7px;
  }
  .acc-img {
    display: flex;
    justify-content: center;
    margin: 67px auto;
  }
}

.top-arrow {
  position: absolute;
  top: -6px;
  left: 19px;
  background: #666666;
  width: 11px;
  height: 11px;
  transform: rotate(45deg);
}
.main-menu {
  &.nav-item {
    list-style: none;
  }
  .dropdown-menu {
    background: $dark;
    min-width: 278px;
    margin-top: 8px;
    border-radius: 0;
  }
  .c-sidebar-nav-link:hover {
    background: $dark2;
  }
  .menu {
    .icon {
      fill: #fff;
      width: 20px;
      height: 20px;
      margin-right: 5px;
    }
  }
  .dropdown-item {
    color: #fff;
    &:hover {
      background-color: $dark2;
    }
  }
  .d-header {
    background-color: $dark;
    border-bottom: 1px solid $border;
    display: flex;
    flex-wrap: wrap;
    .icon {
      fill: #fff;
      width: 50px;
      height: 50px;
    }
    a {
      width: 50%;
      text-align: center;
      color: #fff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 10px;
      &:hover {
        .icon {
          fill: $main;
        }
        color: $main;
      }
    }
  }
  .nav_items_nav {
    a {
      padding: 8px 20px !important;
    }
  }
}
.toggle-icon {
  padding: 11px 0;
  align-items: center;
  justify-content: center;
  display: flex;
  &.anim {
    animation-name: toggle;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
    .icon {
      fill: $main;
      transition: fill 0.3s;
    }
  }
  &.anim-on {
    animation-name: toggle-on;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
    .icon {
      fill: #fff;
      transition: fill 0.3s;
    }
  }
  .icon {
    fill: #fff;
    width: 20px;
    height: 20px;
  }
}
@keyframes toggle {
  from {
  }
  to {
    transform: rotate(90deg);
  }
}
@keyframes toggle-on {
  from {
    transform: rotate(90deg);
  }
  to {
    transform: rotate(0);
  }
}
.rt-Bar {
  position: absolute;
  right: 0;
}
.d-header {
  .search {
    padding: 0 20px;
    display: flex;
    input {
      color: $lightBG;
      font-size: 20px;
    }
    .MuiSvgIcon-root {
      color: $lightBG;
    }
  }
}

.zoom-tool {
  display: flex;
  width: 154px;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  border-left: 1px solid $border;
  border-right: 1px solid $border;
  font-size: 16px;
  input {
    width: 100%;
  }
}
.analyst-menu {
  z-index: 10;
}
.first_steps {
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #aaaaaa;
  height: 40px;
  padding: 0 10px 0 0;
  .path_10 {
    svg {
      width: 25px;
      height: 25px;
    }
  }
  .p2 {
    svg {
      width: 32px;
      height: 25px;
    }
  }
  .tune {
    svg {
      width: 28px;
      height: 27px;
    }
  }
}
.second_hand_step {
  height: 40px;
  display: flex;
  align-items: center;
  .p1 {
    svg {
      width: 20px;
      height: 18px;
    }
  }
  .backup {
    svg {
      width: 27px;
      height: 18px;
    }
  }
  .stop {
    svg {
      width: 19px;
      height: 18px;
    }
  }
  .play_arrow {
    svg {
      width: 14px;
      height: 18px;
    }
  }
  .next_arrrow {
    svg {
      width: 26px;
      height: 18px;
    }
  }
  .next_arrow2 {
    svg {
      width: 19px;
      height: 18px;
    }
  }
  .lines {
    svg {
      width: 28px;
      height: 30px;
    }
  }
}
.third-hand_step {
  align-items: center;
  .sortable {
    svg {
      width: 28px;
      height: 24px;
    }
  }
  .separat_th_hand {
    border-left: 1px solid #aaaaaa;
    border-right: 1px solid #aaaaaa;
    margin: 0 10px 0 4px;
  }
  .mark_,
  .reco_ {
    font-size: 24px;
    font-weight: bold;
    color: #666666;
  }
}
.darkMode_tube {
  background: #707070 !important;
  border-radius: 4px;
  color: #fff;
  font-weight: bold;
  font-size: 16px;
  .muted-color {
    color: #aaaaaa;
    margin: 0;
    padding: 0 !important;
  }
  .darkmode {
    height: 44px;
    display: flex;
    min-width: 235px;
    font-size: 22px;
  }
}
.complete-link {
  border-left: 1px solid $border;
  padding-left: 21px !important;
}
.info-desc-icon {
  border-right: 1px solid $border;
}
.name-full {
  h3 {
    font-size: 16px;
    color: $dark;
  }
  h4 {
    font-size: 13px;
    color: $border;
  }
}
.edit-wrap {
  position: relative;
  .edit {
    cursor: pointer;
    position: absolute;
    top: 3px;
    right: 3px;
    width: 20px;
    height: 20px;
    display: none;
    fill: $dark;
    svg {
      width: 100%;
      height: 100%;
    }
  }
  .delete {
    top: 30px;
    fill: $dark;
  }
  &:hover .edit {
    display: block;
  }
}
.active {
  svg {
    fill: $main;
  }
}
.sectionInfo {
  width: 180px;
  .edit-wrap {
    width: 100%;
    .edit {
      width: 14px;
      height: 14px;
      top: 3px;
      right: 0;
      &.active {
        svg {
          fill: $main;
        }
      }
    }

    .delete {
      right: 19px;
    }
    .close {
      top: 4px;
    }

    .shape {
      right: 35px;
    }
    .center {
      right: 50px;
    }
  }
  .p-4 {
    display: flex;
    flex-wrap: wrap;
  }
  .activeS {
    color: #f4ba18;
    cursor: pointer;
  }
}

.cars {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  form {
    max-width: 200px;
  }
}
.profiles {
  form {
    display: flex;
    align-items: center;
  }
}
.sections {
  flex-direction: column;
  .wrap {
    display: flex;
    padding: 0 !important;
    flex-direction: column;
    p {
      padding: 3px;
      margin: 0;
    }
  }
  .edit-wrap {
    padding: 0 !important;
  }
}
form {
  .group {
    border: 1px solid #ccc;
    margin-bottom: 5px;
  }
}
.regions {
  flex-direction: column;
  .wrap {
    flex-direction: column;
  }
}

.r-item {
  cursor: pointer;
}

.activeR {
  .r-item {
    background-color: $lightGrey;
  }
}

.radioImg {
  width: 100%;
  margin-bottom: 25px !important;
  img {
    max-width: 70px;
  }
  .radio {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .MuiFormGroup-row {
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

.radio-options {
  // align-items: center;
  border-radius: 7px;
  position: relative;
  max-width: 720px;
  margin: 30px 30px;
  font-size: 20px;
  p {
    flex-shrink: 0;
    color: black;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0.1px;
    line-height: 22px;
  }
  button {
    border: 1px solid #ccc;
    background: #eee;
    margin: 0 10px;
    min-width: 135px;
  }
  .row {
    align-items: center;
    margin: 0;
  }
  .mr-2{
    font-size: 14px;
  }
  .mr-4{
    font-weight: bold;
  }
}
.fix_h {
  height: 200px;
  overflow-y: scroll;
}
.comment-dropdown-item {
  white-space: nowrap;
  background: #666666;
  .darkmode {
    font-size: 16px;
    margin: 0;
  }
}
.via-down {
  padding: 10px 55px 5px 10px !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  color: #eeeeee !important;
  svg {
    height: 17px !important;
  }
}
.comment-dropdown-opened {
  display: block;
  font-size: 16px;
  color: #eee;
  background: #666666;
}

.comment-dropdown-closed {
  display: none;
}
.dialog,
.c-header {
  .unit-elem {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-right: 10px;

    p {
      margin: 7px 5px;
    }

    &.active {
      background-color: $main;
      color: #fff;
    }
  }
}

.dialog {
  .unit-elem-s {
    flex-direction: row;
    display: flex;
  }
}

@media only screen and (max-width: 1350px) {
  .tool-bar {
    .icon {
      width: 20px;
      height: 20px;
    }
    .complete-persentage {
      font-size: 16px;
    }
    a,
    span {
      margin-left: 1px;
    }
  }
}
form {
  width: 100%;
}

.write-answer {
  position: relative;
  border-radius: 7px;
  max-width: 720px;
  margin: 15px 30px;
  font-size: 14px;

  textarea {
    padding: 5px 10px;
    width: 100%;
    font-family: 'Roboto', sans-serif;
    outline: none;
    resize: none;
    overflow-y: visible;
    overflow-x: hidden;
  }
  .long-row {
    width: 95%;
  }
  .mr-4{
    font-weight: bold;
  }

  .main-title {
    font-size: 16px;
    font-weight: 500;
    color: #202124;
    // line-height: 135%;
    max-width: 100%;
  }

  .sub-title {
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.1px;
    line-height: 28px;
    color: #202124;
  }

  p {
    white-space: pre-wrap;
    font-family: Roboto, Arial, sans-serif;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.1px;
    line-height: 22px;
    color: #202124;
  }

  [placeholder]:focus::-webkit-input-placeholder {
    transition: text-indent 0.4s 0.4s ease;
    text-indent: -100%;
    opacity: 1;
  }
}

.short-row {
  width: 50%;
}

.MuiOutlinedInput-input {
  width: 640px !important;
  padding: 24px !important;
  margin: 50px auto 0 auto !important;
}

.MuiFormControl-root {
  margin-bottom: 30px !important;
}

.form-main-content {
  width: 60%;
  margin: auto;
}

.btn-block {
  margin: 30px 0px;
  width: 200px;
}

.logo-link {
  text-decoration: none;
  color: #e57534;
}

.save-pdf {
  justify-content: space-between;
  position: sticky;
  bottom: 0px;
}
.info_button{
  display: flex;
  margin-top: 10px;
  margin-left: 30px;
  width: 25px;
  color: black;
}

.btn-pdf {
  width: auto;
  background-image: url('../icons/pdf.png');
  background-color: currentColor;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  cursor: pointer;
}

.file-upload {
  margin-top: 20px;
}

table {
  border: 1px solid rgb(135, 132, 132);
  width: 100%;
  height: auto;
  padding: 1px;
}

thead {
  background-color: #797878;
}


td {
  text-align: center;
  border: 1px solid #ccc;
}

textarea {
  box-sizing:border-box
}
.table-fields {
  font-weight: bold;
  min-height: 60px;
  font-size: 16px;
  font-family: serif;
  background-color: #e7eafd;
  margin: 1px;
  text-align: center;
}
.table-names{
  font-weight: bold;
  display: block;
  margin-top: 15px;
}

.static-inputs{
  width: 100%;
  padding: 1px 0;
  background-color: white;
  border: none;
}



.dynamic-inputs {
  width: 100%;
  padding: 1px 0;
  border: none;
}
.static-inputs{
  width: 100%;
  padding: 1px 0;
  border: none;
  position: relative;
}
.static-fields{
  width: calc(100% - 10px);
  min-height: 30px;
  padding: 3px;
  position:absolute;
  top: 0;
  right: 0;
  height: 100%;
  border: none;
}
.dynamic-fields {
  width: calc(100% - 10px);
  min-height: 30px;
  padding: 3px;
  border:none;
}
.delete-icon-div{
  position: absolute;
  right: -60px;
  top: 13px;
  width: 80px;
  height: 40px
}
.delete-icon {
  margin: auto;
  color: #0a1244;
  font-size: 1.5rem;
  justify-content: center;
  align-items: center;
  display: flex;
  padding-top: 0px;
  height: 1em;
  display: none!important;
}
.table-head {
  position: relative;
  display: flex;
  &:hover{
    .delete-icon {
      display: flex!important;
    }
  }
}

.table-row{
  display: table-row;
  position: relative;
  &:hover{
    .delete-icon {
      display: flex!important;
    }
  }
}
.loading-file{
  color:#0a1244;
}

.empty-space{
  height: 60px;
}

.passPhrase {   
  margin-top: 15px;
}

.deleteIcon {
  position: absolute !important;
  right: 0px;
  top: 0px;
}

.project{
  position: relative;
}

.Header-header-1 {
  background: #0a1244 !important;
}

.ReactModal__Content{
  position: relative !important;
  width: 500px !important;
  top: 20% !important;
  margin: auto;
}

.modal-close-btn{
  position: absolute !important;
  right: 0px;
  top: 0px;
}

.btn-cancel-delete{
  background: none;
  color: #0a1244;
  font-weight: bold; 
  margin-right: 20px;
}

.btn-delet-project{
  background: #e57534;
}

.MuiTab-textColorInherit.Mui-selected{
  color: #0a1244;
}

.error-text{
  color: #e57534;
  font-size: 12px;
}

.info-icon{
  position: absolute;
  color: #e57534;
  top: 0px;
  right: 0px;
}

.more-info{
  border: 1px solid rgb(35, 33, 33);
  position: absolute;
  top: 0px;
  left: 0px;
  width: 94%;
  height: auto;
  padding: 23px;
  border-radius: 7px;
  display:none;
  background: #fff;
  z-index: 999;
}

.info-icon:hover + .more-info{
  
  display: block;
  
}

.lang-icon{
  width:30px;
  height:20px;
  border:1px solid white;
  background-color: currentColor;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  cursor: pointer;
}

.lang-am {
  background-image: url('../icons/am.png');
}

.lang-en {
  background-image: url('../icons/en.png');
}

.scroll-sidebar{
  position: sticky;
  top:100px;
}

.scroll-sidebar-content{
  background: #0a1244 ;
  padding:10px;
  height: 765px;
  overflow: auto;
}

.scroll-item{
    font-size:14px;
    color: #fff;
    margin-bottom: 0px;
    border-bottom: 1px solid white;
    cursor: pointer;
}

.scroll-item:hover{
  color: #e57534;
}

.sidebar{
  position: relative;
}

.closeSidebar{
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 999;
  color: #e57534;
  cursor: pointer;
}

.openSidebar{
  left: 5px;
  color: #0a1244;
 
}

@media only screen and (max-width: 1350px) {
  .main-title{
    font-size: 18px !important;
  }
}




