@import 'variables';

.projects{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  .project{
    min-width: 250px;
    display: flex;
    margin: 10px;
    padding: 15px;
    text-align: center;
    justify-content: center;
    align-items: center;
    background: #faf4ff;
    box-shadow: 0 6px 2px -5px $main-darck, 0 1px 0 0 $main-darck !important;
    a{
      text-decoration: none;
    }
    &.current{
      background-color: $main-darck;
      color:#fff;
      button, a{
        color:#fff
      }
    }
  }
}
.register-form{
  margin-bottom: 20px;
}
.mb-4{
  margin-bottom: 4px;
}
.mb-12{
  margin-bottom: 12px;
}

.create-projects{
  display: flex;
  justify-content: flex-end;
  margin: 0 auto;
  .MuiInputBase-root{
    width: 100%;
    margin-bottom: 20px;
  }
}

.MuiPaper-elevation1 {
  box-shadow: 0 6px 2px -5px #0a1244, 0 1px 0 0 #0a1244 !important;
}

.tabs{
  background-color: #e57534 !important;
  top: 56px!important;
  z-index: 1!important;
}
.PrivateTabIndicator-colorSecondary-9 {
  background-color: #000000!important;
}
